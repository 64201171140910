.scroller {
    /*overflow: auto;*/
    height: 100vh;
    -ms-scroll-snap-type: mandatory;
    scroll-snap-type: y mandatory;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
    -ms-overflow-style: none;  /* IE и Edge */
    scrollbar-width: none;  /* Firefox */
}

.scroller::-webkit-scrollbar {
    display: none;
}

.scroller-item {
    scroll-snap-align: center;
}
